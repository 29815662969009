import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"


const IndexPage = () => (
  <Layout>
    <SEO title="Welcome" />
    <div data-netlify-identity-button>Befome a Whiskey Thief</div>
  </Layout>
)

export default IndexPage
